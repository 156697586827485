<template>
  <div class="awards">
    <ComponentGallery :showCaption="true"/>
  </div>

  <div class="VerticalSpacer_Middle"></div>
  <div class="VerticalSpacer_Middle"></div>
  <div class="VerticalSpacer_Middle"></div>
  <div class="VerticalSpacer_Middle"></div>
  <div class="VerticalSpacer_Middle"></div>
  <div class="VerticalSpacer_Middle"></div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component'
import ComponentGallery from '@/components/Gallery.vue'

@Options({
  components: {
    ComponentGallery
  },

  mounted () {
    
  }
})
export default class Gallery extends Vue {}
</script>