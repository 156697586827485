
import {Options, Vue} from 'vue-class-component'
import ComponentGallery from '@/components/Gallery.vue'

@Options({
  components: {
    ComponentGallery
  },

  mounted () {
    
  }
})
export default class Gallery extends Vue {}
